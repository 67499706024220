<template>
  <v-container class="container-card">
    <v-card>
      <AssistenteEtapasCadastroEmpresa />
    </v-card>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "CadastroEmpresas",

  components: {
    AssistenteEtapasCadastroEmpresa: () =>
      import("@/components/empresa/empresas/AssistenteEtapasCadastroEmpresa")
  },
  methods: {
    ...mapActions({
      fetchView: "sistema/fetchView"
    })
  },

  mounted() {
    this.fetchView("Consulta Cadastro");
  }
};
</script>

<style></style>
